import * as React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Box, CssBaseline, Divider, Typography, Container, TextField, InputAdornment, Accordion, AccordionDetails, AccordionSummary, Link } from '@mui/material';
import WebAppBar from '../WebAppBar';
import Footer from '../Footer';
import myImage from '../../assets/img/website/duotone.png';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqData = [
    {
    "question": "How soon will I receive job opportunities after creating my profile?",
    "answer": "Once you create and complete your profile, a scheduler is automatically set up for you. You can start receiving job opportunities within minutes! Yes, it's that fast—there's no waiting around to find the right opportunity."
    },
    {
    "question": "When will my profile go live?",
    "answer": "Immediately! Once you register, your profile goes live right away—there's no waiting period. You can start connecting with opportunities instantly."
    },
    {
    "question": "How can I apply to jobs?",
    "answer": (
        <>
            To apply for jobs, visit <Link href='https://app.joblixx.com/apply'>Apply page</Link>. There, you will find job opportunities that have been matched to your profile, allowing you to start your applications easily. 
        </>
        ),
    }, 
    {
    "question": "How can I improve my CV/Resume?",
    "answer": "After registering with Joblixx, we create a comprehensive resume for you that is ATS-compliant and downloadable. You can share this resume with recruiters and also feature it in your public profile, enhancing your visibility and opportunities."
    },     
];

export default function GettingStarted() {
    const [searchQuery, setSearchQuery] = React.useState('');
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    
    const handleSearchSubmit = (event) => {
        event.preventDefault();
        // Implement search functionality here
        console.log('Search query:', searchQuery);
    };

    return (
        <Box>
            <Helmet>
                <title>Joblixx - Help center | Getting Started</title>
            </Helmet>
            <CssBaseline />
            <WebAppBar />
            <Box position="relative" height="400px">
                <Box
                    sx={{
                        height: '100%',
                        backgroundImage: `url(https://joblixx-user-docs.blr1.cdn.digitaloceanspaces.com/web/mesh-bg-hc.png)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    sx={{
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        textAlign: 'center',
                        width: '80%'
                    }}
                >
                    <Typography variant="h4" color='primary.lighter' fontSize='clamp(1.8rem,4vw,2.5rem)' gutterBottom>
                        How can we help you?
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSearchSubmit}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            maxWidth: '600px',
                            margin: '0 auto',
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Search for help..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                backgroundColor: 'white',
                                height:'50px',
                                borderRadius: 1,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                },
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Container maxWidth="lg" sx={{ py: 8 }}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{textAlign: 'center', mb:4}}>
                        <Typography component='h2' variant='h4' mb={2}>Getting started</Typography>
                        <Typography>Discover essential steps and tips to efficiently begin your journey on Joblixx, ensuring you're set up for a successful job search experience.</Typography>
                    </Box>
                    {faqData.map((faq, index) => (
                        <Accordion
                            key={`panel${index + 1}`}
                            expanded={expanded === `panel${index + 1}`}
                            onChange={handleChange(`panel${index + 1}`)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index + 1}d-content`}
                                id={`panel${index + 1}d-header`}
                            >
                                <Typography component="h3" variant="subtitle1">
                                    {faq.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body2">
                                    {faq.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </Container>
            <Divider />
            <Footer />
        </Box>
    );
}

// GettingStarted.propTypes = {
//     // Add any props if needed
// };