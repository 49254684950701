import * as React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Box, CssBaseline, Divider, Typography, Container, TextField, InputAdornment, Accordion, AccordionDetails, AccordionSummary, Link } from '@mui/material';
import WebAppBar from '../WebAppBar';
import Footer from '../Footer';
import myImage from '../../assets/img/website/duotone.png';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqData = [
    {
        "question": "How do I contact customer support if I have a question or issue?",
        "answer": (
            <>
                You can contact our customer support team by emailing <Link href='mailto:support@joblixx.com'>support@joblixx.com</Link>. We are committed to assisting you promptly and ensuring your experience with Joblixx is smooth and enjoyable.
            </>
        ),
    },
    {
        "question": "How does the cancellation policy work?",
        "answer": "You can cancel your plan at any time during its duration. Future payments will be terminated, along with all services associated with the selected plan. Please note that this action is permanent and cannot be undone once initiated. For further details, refer to our Refund and Cancellation Policy."
    },
    {
        "question": "How often should I update my profile?",
        "answer": "Regularly updating your profile and preferences enables us to make better recommendations for you. Ideally, aim to update your profile every alternate day. If that's not feasible, updating it once a week is also beneficial."
    }, 
    {
        "question": "How is my data managed?",
        "answer": "Your data is securely stored and only visible to you. We never share your sensitive information with anyone without your consent. Protecting your data is our highest priority."
    },
    {
        "question": "Can I share my Joblixx profile?",
        "answer": "Yes, you can! Joblixx provides you with a public profile (Resume) that can be shared with anyone."
    },
    {
        "question": "How do I create the best profile?",
        "answer": (<>
            Creating a strong profile is essential for helping us and potential employers understand your qualifications.<br />
            Follow these simple steps to build the best profile:
            <ul>
            <li> <b>Add your resume</b>: Upload your most recent resume to showcase your experience.</li>
            <li> <b>Add 'About Me'</b>: Write a brief introduction highlighting your career goals and strengths.</li>
            <li> <b>Add Skills</b>: List relevant skills that demonstrate your expertise.</li>
            <li> <b>Add Experience</b>: Include your work history, focusing on roles that align with your career objectives.</li>
            <li> <b>Add Education</b>: Share your educational background to provide context to your qualifications.</li>
            </ul>
        </>)
    }
];

export default function Profile() {
    const [searchQuery, setSearchQuery] = React.useState('');
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    
    const handleSearchSubmit = (event) => {
        event.preventDefault();
        // Implement search functionality here
        console.log('Search query:', searchQuery);
    };

    return (
        <Box>
            <Helmet>
                <title>Joblixx - Help center | Profile</title>
            </Helmet>
            <CssBaseline />
            <WebAppBar />
            <Box position="relative" height="400px">
                <Box
                    sx={{
                        height: '100%',
                        backgroundImage: `url(https://joblixx-user-docs.blr1.cdn.digitaloceanspaces.com/web/mesh-bg-hc.png)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    sx={{
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        textAlign: 'center',
                        width: '80%'
                    }}
                >
                    <Typography variant="h4" color='primary.lighter' fontSize='clamp(1.8rem,4vw,2.5rem)' gutterBottom>
                        How can we help you?
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSearchSubmit}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            maxWidth: '600px',
                            margin: '0 auto',
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Search for help..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                backgroundColor: 'white',
                                height:'50px',
                                borderRadius: 1,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                },
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Container maxWidth="lg" sx={{ py: 8 }}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{textAlign: 'center', mb:4}}>
                        <Typography component='h2' variant='h4' mb={2}>Profile</Typography>
                        <Typography>Learn how to view and update your profile status, manage your availability, and ensure your information is always current on Joblixx.</Typography>
                    </Box>
                    {faqData.map((faq, index) => (
                        <Accordion
                            key={`panel${index + 1}`}
                            expanded={expanded === `panel${index + 1}`}
                            onChange={handleChange(`panel${index + 1}`)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index + 1}d-content`}
                                id={`panel${index + 1}d-header`}
                            >
                                <Typography component="h3" variant="subtitle1">
                                    {faq.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body2">
                                    {faq.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </Container>
            <Divider />
            <Footer />
        </Box>
    );
}

// Profile.propTypes = {
//     // Add any props if needed
// };