import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/system';

const tiers = [
  {
    title: 'Starter',
    price: '0',
    description: [
      'Automated Job Search',
      'Notification Scheduler',
      'Application Tracking',
      'Job Application History',
      'Email support (within 48 hours)',
    ],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
    buttonUrl: 'https://app.joblixx.com/initial-info', 
  },
  {
    title: 'Seeker',
    subheader: 'Recommended',
    price: {
      monthly: '1399',
      '3months': '1149',
      '6months': '999',
    },
    description: [
      'Automated Job Search',
      'Profile-Based Job Matching',
      'Instant Job Alerts (via Message, Email)',
      'Job Search Filters (Location, Industry, Salary, etc)',
      'Automated Email Applications',
      'Application Tracking',
      'Job Application History',
      'Job Match Analysis',
      'Personalized User Dashboard',
      'Resume Generator',
      'Cover Letter Generator',
      'Job Description Summarizer',
      'Notification Scheduler',
      'Profile-Driven Job Ratings',
      'Interactive User Interface',
      'Profile Privacy Settings',
      'Dedicated Customer Support'
    ],  
    discount: {
      monthly: '0',
      '3months': '20%',
      '6months': '30%',
    },
    buttonText: 'Start now',
    buttonVariant: 'contained',
    buttonUrl: 'https://app.joblixx.com/initial-info',
    disclaimer: '* Billed upfront for duration',
  },
  // {
  //   title: 'Achiever',
  //   price: {
  //     monthly: '1450',
  //     '3months': '1300',
  //     '6months': '1200',
  //   },
  //   description: [
  //     '50 users included',
  //     '30 GB of storage',
  //     'Help center access',
  //     'Phone & email support',
  //     '+ gst',
  //   ],
  //   discount: {
  //     monthly: '0',
  //     '3months': '10%',
  //     '6months': '17%',
  //   },
  //   buttonText: 'Start now',
  //   buttonVariant: 'outlined',
  //   disclaimer: '* Billed upfront for duration',
  // },
];

export default function PricingDetail() {
  const [subscriptionPeriod, setSubscriptionPeriod] = React.useState('monthly'); // State for selected subscription period

  const handlePeriodChange = (event, newPeriod) => {
    if (newPeriod !== null) {
      setSubscriptionPeriod(newPeriod);
    }
  };

  const getPriceWithDiscount = (title, duration) => {
    const tier = tiers.find((t) => t.title === title);
    if (tier && tier.price[duration]) {
      const price = parseFloat(tier.price[duration]);
      const discount = tier.discount[duration];
      if (discount && discount.includes('%')) {
        return `${price}`;
      } else {
        return `${price}`;
      }
    }
    return `${tier.price}`;
  };

  const getDiscountText = (title, duration) => {
    const tier = tiers.find((t) => t.title === title);
    if (tier && tier.discount[duration]) {
      const discount = tier.discount[duration];
      if (discount !== '0') {
        return `Save upto ${discount}`;
      }
    }
    return ''; // Return empty string if discount is 0 or not found
  };

  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
        marginTop: { xs: '17%', sm: '3%', md: '3%' },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '100%' },
          textAlign: { xs: 'center', sm: 'center', md: 'center' },
        }}
      >
        <Box component="span" sx={{ display: { xs: 'block', sm: 'inline' } }}>
          <Typography
            variant="h3"
            color="text.primary"
            fontWeight="600"
            sx={{
              display: { xs: 'inline', sm: 'inline' },
              fontSize: { xs: '2rem', sm: '2.125rem', md: '3rem' }, // Responsive font sizes
            }}
          >
            You find the&nbsp;
            <span style={{ borderBottom: '5px solid', borderBottomColor: '#2C9BD2' }}>
              right plan
            </span>,
          </Typography>
        </Box>
        <Box component="span" sx={{ display: { xs: 'block', sm: 'inline' }, mt: { xs: 2, sm: 0 } }}>
          <Typography
            variant="h3"
            color="text.primary"
            fontWeight="600"
            sx={{
              display: { xs: 'inline', sm: 'inline' },
              fontSize: { xs: '2rem', sm: '2.125rem', md: '3rem' }, // Responsive font sizes
            }}
          >
            {' '}
            we'll find the&nbsp;
            <span style={{ borderBottom: '5px solid', borderBottomColor: '#2C9BD2' }}>
              right job
            </span>.
          </Typography>
        </Box>
        <Typography variant="body1" color="text.secondary" sx={{ marginTop: { xs: '10%', sm: '3%', md: '3%' } }}>
          Discover the perfect plan tailored to your needs. We'll match you with the ideal job to achieve your career goals.
          <br />
          Select the plan the suites your needs with the duration you want to get started with.
        </Typography>
      </Box>
      <ToggleButtonGroup
        value={subscriptionPeriod}
        color='primary'
        exclusive
        onChange={handlePeriodChange}
        aria-label="subscription period"
        sx={{ marginBottom: '10px' }}
      >
        <ToggleButton value="monthly" aria-label="Monthly">
          Monthly
        </ToggleButton>
        <ToggleButton value="3months" aria-label="3 Months">
          3 Months
        </ToggleButton>
        <ToggleButton value="6months" aria-label="6 Months">
          6 Months
        </ToggleButton>
      </ToggleButtonGroup>
      <Grid container spacing={3} alignItems="stretch" justifyContent="center">
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={6}
            md={5}
          >
            <Card
              sx={(theme) => ({
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                ...(tier.title === 'Seeker' && {
                  border: 'none',
                  boxShadow:
                    theme.palette.mode === 'light'
                      ? `0 8px 12px hsla(210, 98%, 42%, 0.2)`
                      : `0 8px 12px hsla(0, 0%, 0%, 0.8)`,
                  background:
                    // 'radial-gradient(circle at 50% 0%, hsl(210, 98%, 35%), hsl(210, 100%, 16%))',
                    'radial-gradient(circle at 50% 0%, hsl(200, 65%, 15%), hsl(200, 65%, 35%))',
                }),
              })}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                    color: tier.title === 'Seeker' ? 'grey.100' : '',
                  }}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.title === 'Seeker' && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label={tier.subheader}
                      size="small"
                      sx={{
                        borderColor: 'hsla(220, 60%, 99%, 0.3)',
                        // backgroundColor: 'hsla(220, 60%, 99%, 0.1)',
                        backgroundColor: 'hsl(200, 65%, 25%)',
                        '& .MuiChip-label': {
                          color: 'hsl(0, 0%, 100%)',
                        },
                        '& .MuiChip-icon': {
                          color: 'primary.light',
                        },
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    color: tier.title === 'Seeker' ? 'grey.50' : undefined,
                  }}
                >
                  <Typography component="h3" variant="h2">
                    ₹{getPriceWithDiscount(tier.title, subscriptionPeriod)}
                  </Typography>
                  <Typography component="h3" variant="h6">
                    &nbsp;/mo
                  </Typography>
                  {tier.discount && tier.discount[subscriptionPeriod] !== '0' && (
                    <Typography
                        variant="body2"
                        component="span"
                        sx={{
                        borderColor: '#2C9BD2',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        padding: '2px 6px',
                        marginLeft: '8px',
                        }}
                    >
                        {getDiscountText(tier.title, subscriptionPeriod)}
                    </Typography>
                    )}
                </Box>
                <Typography variant="caption" sx={{ mt: 2, color: tier.title === 'Seeker' ? 'grey.50' : undefined, }}>
                  {tier.disclaimer}
                </Typography>
                <CardActions sx={{ marginTop:'15px'}}> 
                <Button fullWidth href={tier.buttonUrl} variant={tier.buttonVariant}>
                  {tier.buttonText}
                </Button>
                </CardActions>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.8,
                    borderColor: 'divider',
                  }}
                />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: 'flex',
                      gap: 1.5,
                      alignItems: 'center',
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color:
                          tier.title === 'Seeker'
                            ? 'primary.light'
                            : 'primary.main',
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      component={'span'}
                      sx={{
                        color: tier.title === 'Seeker' ? 'grey.50' : undefined,
                      }}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
