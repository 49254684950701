// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// Website Routes
import LandingPage from './pages/LandingPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsandConditions from './pages/TermsandConditions';
import CookiePolicy from './pages/CookiePolicy';
import PricingPage from './pages/PricingPage';
// Help Center Routes
import HelpCenter from './pages/HelpCenter';
import AboutJoblixx from './components/help-center/AboutJoblixx';
import GettingStarted from './components/help-center/GettingStarted';
import Profile from './components/help-center/Profile';
import JobMatching from './components/help-center/JobMatching';
import SchedulerHC from './components/help-center/SchedulerHC';
import SettingsHC from './components/help-center/SettingsHC';
// Utils Routes
import ComingSoon from './components/assets/ComingSoon';
import NotFound from './pages/404Page';

import reportWebVitals from './reportWebVitals';
import getLPTheme from './getLPTheme';

const theme = createTheme(getLPTheme('light'));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          {/* Web Routes */}
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/terms-and-conditions" element={<TermsandConditions />} />
          <Route exact path="/cookie-policy" element={<CookiePolicy />} />
          <Route exact path="/plans" element={<PricingPage />} />
          {/* Help center Routes */}
          <Route exact path='/help-center' element={<HelpCenter />} />
          <Route exact path='/help-center/about-joblixx' element={<AboutJoblixx />} />
          <Route exact path='/help-center/getting-started' element={<GettingStarted />} />
          <Route exact path='/help-center/profile' element={<Profile />} />
          <Route exact path='/help-center/job-matching' element={<JobMatching />} />
          <Route exact path='/help-center/scheduler' element={<SchedulerHC />} />
          <Route exact path='/help-center/settings' element={<SettingsHC />} />
          {/* Utils routes */}
          <Route exact path='/coming-soon' element={<ComingSoon />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
