import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import HowToRegIcon from '@mui/icons-material/HowToReg';

const items = [
  {
    icon: <SearchIcon fontSize="large" />,
    title: 'Build your profile',
    description:
      'Create a detailed and compelling profile that showcases your skills, experience, and professional achievements.',
  },
  {
    icon: <ScheduleSendIcon fontSize="large" />,
    title: 'Create scheduler',
    description:
      'Specify your job preferences, including your desired role, domain, location. Setup schedulers to find the best matches.',
  },
  {
    icon: <HowToRegIcon fontSize="large" />,
    title: 'Discover job matches',
    description:
      // 'Get matched with job opportunities that are best aligned with your profile and secure your next job.',
      "After creating profile start receiving job opportunities within minutes! Yes, it's that fast—there's no waiting around to find the right opportunity.",
  },
];

export default function Solution() {
  return (
    <Container id="solution" sx={{ py: { xs: 8, sm: 16 } }}>
      <Box sx={{ textAlign: 'center', mb: 6 }}>
        <Typography component="h2" variant="h2" color="text.primary" sx={{fontSize: 'clamp(2rem, 3vw, 3.5rem)'}} gutterBottom>
          Job Hunting Has Never Been This Easy
        </Typography>
        <Typography color="text.secondary" sx={{ mb: 4, maxWidth: '800px', mx: 'auto' }}>
          Finding the right job can be challenging, but we're here to make it easier for you. 
          Our automated matching system connects you with opportunities in three simple steps.
        </Typography>
      </Box>

      <Grid container spacing={4} sx={{ mb: 6 }}>
        {items.map((item, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Card 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                backgroundColor: 'background.default',
                flexDirection: 'column',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  // boxShadow: (theme) => theme.shadows[4],
                },
              }}
            >
              <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <Box sx={{ mb: 2, color: 'primary.main' }}>{item.icon}</Box>
                <Typography gutterBottom variant="h5" component="h3">
                  {item.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button 
          variant="contained" 
          size="large" 
          href="https://app.joblixx.com/initial-info" 
          sx={{ 
            minWidth: 200,
            fontSize: '1.1rem',
            py: 1.5,
            px: 4,
            borderRadius: 15,
          }}
        >
          Get Started
        </Button>
      </Box>
    </Container>
  );
}