import * as React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Box, CssBaseline, Divider, Typography, Container, TextField, InputAdornment, Accordion, AccordionDetails, AccordionSummary, Link } from '@mui/material';
import WebAppBar from '../WebAppBar';
import Footer from '../Footer';
import myImage from '../../assets/img/website/duotone.png';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqData = [
    {
        "question": "How do I manage notifications?",
        "answer": "You can manage your notification preferences by navigating to the 'Settings' tab in the app. From there, you can customize the types of notifications you wish to receive and adjust the frequency of job alerts."
    },
    {
    "question": "How do I change or reset my password?",
    "answer": (
        <>
            If you've forgotten your password, you can reset it by visiting <Link href='https://app.joblixx.com/forgot'>Forgot password</Link> page. To change your password, go to the 'Settings' page in the app and update it from there.
        </>
        ),
    },     
    {
    "question": "How do I deactivate my account?",
    "answer": "To deactivate your account, head to the 'Settings' tab in the app and follow the steps to deactivate. This action will disable your profile and stop all job notifications."
    },     
];

export default function SettingsHC() {
    const [searchQuery, setSearchQuery] = React.useState('');
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    
    const handleSearchSubmit = (event) => {
        event.preventDefault();
        // Implement search functionality here
        console.log('Search query:', searchQuery);
    };

    return (
        <Box>
            <Helmet>
                <title>Joblixx - Help center | Getting Started</title>
            </Helmet>
            <CssBaseline />
            <WebAppBar />
            <Box position="relative" height="400px">
                <Box
                    sx={{
                        height: '100%',
                        backgroundImage: `url(https://joblixx-user-docs.blr1.cdn.digitaloceanspaces.com/web/mesh-bg-hc.png)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    sx={{
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        textAlign: 'center',
                        width: '80%'
                    }}
                >
                    <Typography variant="h4" color='primary.lighter' fontSize='clamp(1.8rem,4vw,2.5rem)' gutterBottom>
                        How can we help you?
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSearchSubmit}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            maxWidth: '600px',
                            margin: '0 auto',
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Search for help..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                backgroundColor: 'white',
                                height:'50px',
                                borderRadius: 1,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                },
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Container maxWidth="lg" sx={{ py: 8 }}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{textAlign: 'center', mb:4}}>
                        <Typography component='h2' variant='h4' mb={2}>Settings</Typography>
                        <Typography>Learn how to customize your Joblixx settings, manage notification preferences, update personal information, and securely change your password.</Typography>
                    </Box>
                    {faqData.map((faq, index) => (
                        <Accordion
                            key={`panel${index + 1}`}
                            expanded={expanded === `panel${index + 1}`}
                            onChange={handleChange(`panel${index + 1}`)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index + 1}d-content`}
                                id={`panel${index + 1}d-header`}
                            >
                                <Typography component="h3" variant="subtitle1">
                                    {faq.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body2">
                                    {faq.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </Container>
            <Divider />
            <Footer />
        </Box>
    );
}

// SettingsHC.propTypes = {
//     // Add any props if needed
// };