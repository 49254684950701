import React, { useState } from "react"
import { Snackbar, Button, Typography, Link } from "@mui/material"

const CookieConsent = () => {
  const [open, setOpen] = useState(true)

  const handleAccept = () => {
    // Here you would typically set a cookie or local storage item
    // to remember that the user has accepted the cookie policy
    console.log("Cookies accepted")
    setOpen(false)
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      sx={{
        maxWidth:'400px'
      }}
      open={open}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "1rem",
          borderRadius: "15px",
          border: '1px solid #2c9bd2',
          boxShadow: "0 3px 5px rgba(0,0,0,0.2)",
        }}
      >
        <Typography variant="body1" style={{ marginBottom: "1rem" }}>
        We use cookies to improve your experience, analyze traffic, and ensure security. Click "Accept" to consent. Read our <Link href="/cookie-policy" color="#2c9bd2" underline="hover">Cookie Policy</Link>.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleAccept}>
          Accept
        </Button>
      </div>
    </Snackbar>
  )
}

export default CookieConsent

