import * as React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Box, CssBaseline, Divider, Typography, Grid, Card, CardContent, CardActionArea, Container, TextField, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import WebAppBar from '../components/WebAppBar';
import Footer from '../components/Footer';
import myImage from '../assets/img/website/duotone.png';
import SearchIcon from '@mui/icons-material/Search';

// Import icons
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AssignmentIcon from '@mui/icons-material/Assignment';
import GroupIcon from '@mui/icons-material/Group';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsIcon from '@mui/icons-material/Settings';
import JoblixxLogomark from '../assets/img/brand/Joblixx_Logomark.svg';

const helpItems = [
  {
    title: "About Joblixx",
    description: "Learn how to get started with Joblixx and discover the key benefits of creating your profile for job search automation.",
    icon: <PersonAddIcon />,
    path: "/help-center/about-joblixx"
  },
  {
    title: "Getting started",
    description: "Discover essential steps and tips to efficiently begin your journey on Joblixx, ensuring you're set up for a successful job search experience.",
    icon: <PlayArrowIcon />,
    path: "/help-center/getting-started"
  },
  {
    title: "Profile",
    description: "Learn how to view and update your profile status, manage your availability, and ensure your information is always current on Joblixx.",
    icon: <AccountBoxIcon />,
    path: "/help-center/profile"
  },
  {
    title: "Job matching",
    description: "Learn how to improve the accuracy of your job matches and streamline your application process for better results.",
    icon: <AssignmentIcon />,
    path: "/help-center/job-matching"
  },
  {
    title: "Scheduler",
    description: "Get step-by-step guidance on using the scheduler effectively, ensuring it's set up properly to maximize its functionality and streamline your job search.",
    icon: <GroupIcon />,
    path: "/help-center/scheduler"
  },
  {
    title: "Settings",
    description: "Learn how to customize your Joblixx settings, manage notification preferences, update personal information, and securely change your password.",
    icon: <SettingsIcon />,
    path: "/help-center/settings"
  }
];

export default function HelpCenter() {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = React.useState('');

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    
    const handleSearchSubmit = (event) => {
        event.preventDefault();
        // Implement search functionality here
        console.log('Search query:', searchQuery);
    };

    return (
        <Box>
            <Helmet>
                <title>Joblixx - Help center</title>
            </Helmet>
            <CssBaseline />
            <WebAppBar />
            <Box position="relative" height="400px">
                <Box
                    sx={{
                        height: '100%',
                        backgroundImage: `url(https://joblixx-user-docs.blr1.cdn.digitaloceanspaces.com/web/mesh-bg-hc.png)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    sx={{
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        textAlign: 'center',
                        width: '80%'
                    }}
                >
                    <Typography variant="h4" color='primary.lighter' fontSize='clamp(1.8rem,4vw,2.5rem)' gutterBottom>
                        How can we help you?
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSearchSubmit}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            maxWidth: '600px',
                            margin: '0 auto',
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Search for help..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                backgroundColor: 'white',
                                height:'50px',
                                borderRadius: 1,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                },
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Container maxWidth="lg" sx={{ py: 4 }}>
                <Grid container spacing={3} justifyContent="center">
                    {helpItems.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card sx={{ height: '100%', backgroundColor: 'background.default', border: '1px solid', borderColor: 'divider' }}>
                                <CardActionArea onClick={() => navigate(item.path)} sx={{ height: '100%' }}>
                                    <CardContent sx={{ 
                                        textAlign: 'center', 
                                        height: '100%', 
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        justifyContent: 'center',
                                        p: 3
                                    }}>
                                        <Box sx={{ 
                                            backgroundColor: 'background.default', 
                                            border: '1px dashed',
                                            borderColor: 'primary.main',
                                            borderRadius: '50%', 
                                            width: '60px', 
                                            height: '60px', 
                                            display: 'flex', 
                                            justifyContent: 'center', 
                                            alignItems: 'center', 
                                            margin: '0 auto 16px' 
                                        }}>
                                            {React.cloneElement(item.icon, { sx: { color: 'primary.main', fontSize: 30 } })}
                                        </Box>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {item.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {item.description}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Divider />
            <Footer />
        </Box>
    );
}

HelpCenter.propTypes = {
    // Add any props if needed
};