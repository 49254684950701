import * as React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Box, CssBaseline, Divider, Typography, Container, TextField, InputAdornment, Accordion, AccordionDetails, AccordionSummary, Link } from '@mui/material';
import WebAppBar from '../WebAppBar';
import Footer from '../Footer';
import myImage from '../../assets/img/website/duotone.png';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqData = [
    {
        "question": "What is the limit for Schedulers?",
        "answer": "The number of schedulers you can create depends on your subscription plan. Some plans offer unlimited schedulers for managing multiple roles. Each scheduler can be customized with a name, description, communication mode, and preferred time slots. You can manage and configure your schedulers in the 'Notification' section of the application."
    },     
    {
        "question": "How many locations should I add?",
        "answer": "By default, you can add up to five locations. Adding more locations increases your chances of finding the right opportunities tailored to your preferences."
    },     
    {
    "question": "How do I create schedulers?",
    "answer": (
        <>
            After logging in, visit <Link href='https://app.joblixx.com/schedulers'>Schedulers page</Link> to create a new scheduler. Click on 'Create Scheduler,' then fill out the form with your desired job title, select when you'd like to receive notifications, and choose your job matching preferences. Once saved, your scheduler will automate your job hunting process from there.
        </>
        ),
    },     
    {
    "question": "How do I delete schedulers?",
    "answer": (
        <>
            Schedulers cannot be deleted once created, but you can deactivate them by following these steps: <Link href='https://app.joblixx.com/schedulers'>Schedulers page</Link> and locate the schedulers you’ve created. Click on the options menu (⋮) next to the scheduler, and select 'Deactivate Scheduler.' Once deactivated, the scheduler will no longer be active and cannot be edited.
        </>
        ),
    },     
];

export default function SchedulerHC() {
    const [searchQuery, setSearchQuery] = React.useState('');
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    
    const handleSearchSubmit = (event) => {
        event.preventDefault();
        // Implement search functionality here
        console.log('Search query:', searchQuery);
    };

    return (
        <Box>
            <Helmet>
                <title>Joblixx - Help center | Scheduler</title>
            </Helmet>
            <CssBaseline />
            <WebAppBar />
            <Box position="relative" height="400px">
                <Box
                    sx={{
                        height: '100%',
                        backgroundImage: `url(https://joblixx-user-docs.blr1.cdn.digitaloceanspaces.com/web/mesh-bg-hc.png)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    sx={{
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        textAlign: 'center',
                        width: '80%'
                    }}
                >
                    <Typography variant="h4" color='primary.lighter' fontSize='clamp(1.8rem,4vw,2.5rem)' gutterBottom>
                        How can we help you?
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSearchSubmit}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            maxWidth: '600px',
                            margin: '0 auto',
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Search for help..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                backgroundColor: 'white',
                                height:'50px',
                                borderRadius: 1,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                },
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Container maxWidth="lg" sx={{ py: 8 }}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{textAlign: 'center', mb:4}}>
                        <Typography component='h2' variant='h4' mb={2}>Scheduler</Typography>
                        <Typography>Get step-by-step guidance on using the scheduler effectively, ensuring it's set up properly to maximize its functionality and streamline your job search.</Typography>
                    </Box>
                    {faqData.map((faq, index) => (
                        <Accordion
                            key={`panel${index + 1}`}
                            expanded={expanded === `panel${index + 1}`}
                            onChange={handleChange(`panel${index + 1}`)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index + 1}d-content`}
                                id={`panel${index + 1}d-header`}
                            >
                                <Typography component="h3" variant="subtitle1">
                                    {faq.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body2">
                                    {faq.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </Container>
            <Divider />
            <Footer />
        </Box>
    );
}

// SchedulerHC.propTypes = {
//     // Add any props if needed
// };