import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Container, Grid } from '@mui/material';
import { styled } from '@mui/system';
import SitemarkIcon from '../components/SitemarkIcon';

const StyledBox = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(3),
}));

const StyledImage = styled('img')({
  maxWidth: '100%',
  height: 'auto',
  marginBottom: '2rem',
});

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <StyledBox>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={4}
          alignItems="center"
          justifyContent="center"
          direction={{ xs: 'column', md: 'row' }} 
        >
          <Grid item xs={12} md={6}>
            <StyledImage
              src="https://joblixx-user-docs.blr1.cdn.digitaloceanspaces.com/web/bg-404.png"
              alt="404 Error - Page Not Found"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box textAlign={'center'}>
                <SitemarkIcon />
            </Box>
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              align="center"
              sx={{ fontWeight: 'bold', color: 'primary.main' }}
            >
              Uh-oh! This page isn't hiring.
            </Typography>
            <Typography variant="h6" paragraph align="center" sx={{ mb: 4 }}>
              But don't worry, we're experts at finding what you need!
            </Typography>
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => navigate('/')}
                sx={{
                  borderRadius: '50px',
                  padding: '12px 32px',
                  fontSize: '1.1rem',
                  textTransform: 'none',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  '&:hover': {
                    boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
                  },
                }}
              >
                Head back to homepage
              </Button>
            </Box>
            <Typography variant="body1" align="center" sx={{ mt: 4, fontStyle: 'italic' }}>
              Let's get your job search back on track!
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </StyledBox>
  );
};

export default NotFound;
