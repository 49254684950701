import * as React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Box, CssBaseline, Divider, Typography, Container, TextField, InputAdornment, Accordion, AccordionDetails, AccordionSummary, Link } from '@mui/material';
import WebAppBar from '../WebAppBar';
import Footer from '../Footer';
import myImage from '../../assets/img/website/duotone.png';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqData = [
    {
        "question": "About Joblixx",
        "answer": "At Joblixx, our mission is to make life easier for job seekers by connecting their talents with the right opportunities. We leverage advanced technology to streamline the job search process, saving you valuable time on repetitive tasks. This allows you to focus on what truly matters. Our end-to-end solution simplifies the entire journey, meaning you only need to engage with us once, and we handle the rest. With unmatched accuracy, we match you with job openings that align perfectly with your skills and aspirations."
    },
  {
    question: "Why should I join Joblixx?",
    answer: (
      <>
        Joblixx simplifies your job search journey, helping you land your ideal role with ease. Here’s how we make a difference:
        <ul>
            <li><b>Simplified process:</b> Receive opportunities that align with your skills, experience, and preferences, so you only see relevant roles.</li>
            <li><b>Tailored job matches:</b> We handle the time-consuming tasks, allowing you to focus on what matters most—your career.</li>
            <li><b>Access to top employers:</b> Connect with leading companies and industries, ensuring you have access to the best opportunities available.</li>
            <li><b>Flexible approach:</b> Set your preferences and work on your terms, letting you take control of your job search at your own pace.</li>
        </ul>
      </>
    ),
  },
  {
    "question": "Is Joblixx really free?",
    "answer": "Yes, it absolutely is! Joblixx is dedicated to helping you achieve your dream job by connecting you with the best opportunities available in the market. While we do offer a paid plan with additional features to enhance your experience, you can start using Joblixx without any cost. Our free services are designed to provide you with valuable tools and resources to kickstart your job search journey effectively."
  }, 
  {
    "question": "How to join Joblixx?",
    "answer": (
        <>
            Joining Joblixx is easy! Simply <Link href='/initial-info'>Get started</Link> here, and you’ll be guided through the process. After that, you’re all set to embark on your job search journey!
        </>
        ),
  },
  {
    "question": "How can Joblixx help me find my next role?",
    "answer": "At Joblixx, we are dedicated to connecting you with the right opportunities that match your unique talents and aspirations. By gathering essential information about your skills, experience, and career goals, we align you with the most suitable job openings. Our advanced job matching technology is among the best in the industry, ensuring that you receive personalized recommendations tailored to your profile. Let us simplify your job search and guide you to your next great role."
  },
];

export default function AboutJoblixx() {
    const [searchQuery, setSearchQuery] = React.useState('');
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    
    const handleSearchSubmit = (event) => {
        event.preventDefault();
        // Implement search functionality here
        console.log('Search query:', searchQuery);
    };

    return (
        <Box>
            <Helmet>
                <title>Joblixx - Help center | About Joblixx</title>
            </Helmet>
            <CssBaseline />
            <WebAppBar />
            <Box position="relative" height="400px">
                <Box
                    sx={{
                        height: '100%',
                        backgroundImage: `url(https://joblixx-user-docs.blr1.cdn.digitaloceanspaces.com/web/mesh-bg-hc.png)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    sx={{
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        textAlign: 'center',
                        width: '80%'
                    }}
                >
                    <Typography variant="h4" color='primary.lighter' fontSize='clamp(1.8rem,4vw,2.5rem)' gutterBottom>
                        How can we help you?
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSearchSubmit}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            maxWidth: '600px',
                            margin: '0 auto',
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Search for help..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                backgroundColor: 'white',
                                height:'50px',
                                borderRadius: 1,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                },
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Container maxWidth="lg" sx={{ py: 8 }}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{textAlign: 'center', mb:4}}>
                        <Typography component='h2' variant='h4' mb={2}>About Joblixx</Typography>
                        <Typography>Learn how to get started with Joblixx and discover the key benefits of creating your profile for job search automation.</Typography>
                    </Box>
                    {faqData.map((faq, index) => (
                        <Accordion
                            key={`panel${index + 1}`}
                            expanded={expanded === `panel${index + 1}`}
                            onChange={handleChange(`panel${index + 1}`)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index + 1}d-content`}
                                id={`panel${index + 1}d-header`}
                            >
                                <Typography component="h3" variant="subtitle1">
                                    {faq.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body2">
                                    {faq.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </Container>
            <Divider />
            <Footer />
        </Box>
    );
}

// AboutJoblixx.propTypes = {
//     // Add any props if needed
// };