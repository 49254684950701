import React, { useState } from 'react';
import { Box, Typography, IconButton, Card, CardContent, Grid, Slide } from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: '15px',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  cursor: 'pointer',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    elevation: 8,
    transform: 'translateY(-5px)',
    borderColor: theme.palette.primary.light,
  },
}));

const ArrowButton = styled(IconButton)(({ theme }) => ({
  border: '1px solid',
  position: 'absolute',
  top: '55%',
  transform: 'translateY(-60%)',
  backgroundColor: theme.palette.background.default,
  '&:hover': {
    backgroundColor: theme.palette.primary.lighter,
  },
  zIndex: 1,
}));

const jobCategories = [
  {
    type: 'Roles',
    items: [
      { title: 'Full Stack Developer', jobs: '20.5K+' },
      { title: 'Data Scientist', jobs: '11.8K+' },
      { title: 'Product Manager', jobs: '3.5K+' },
      { title: 'UX Designer', jobs: '2.4K+' },
      { title: 'Marketing Manager', jobs: '1.5K+' },
      { title: 'Graphic Designer', jobs: '2.2K+' },
    ],
  },
  {
    type: 'Locations',
    items: [
      { title: 'Mumbai', jobs: '15K+' },
      { title: 'Bengaluru', jobs: '12K+' },
      { title: 'Hyderabad', jobs: '10K+' },
      { title: 'Pune', jobs: '8K+' },
      { title: 'Delhi', jobs: '7K+' },
      { title: 'Chennai', jobs: '6K+' },
    ],
  },
  {
    type: 'Skills',
    items: [
      { title: 'JavaScript', jobs: '25K+' },
      { title: 'Python', jobs: '20K+' },
      { title: 'Data Analysis', jobs: '15K+' },
      { title: 'Project Management', jobs: '12K+' },
      { title: 'Sales', jobs: '10K+' },
      { title: 'Machine Learning', jobs: '8K+' },
    ],
  },
];

export default function JobSearchSlider() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const theme = useTheme();

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % jobCategories.length);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + jobCategories.length) % jobCategories.length);
  };

  const handleCardClick = () => {
    window.location.href = 'https://app.joblixx.com/initial-info'; 
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: 'auto', padding: 4, mt:6, mb:6 }}>
      <Typography component="h2" variant="h4"  gutterBottom>
        Discover Jobs Across Popular{' '}
        <Typography
          component="h2"
          variant="h4"
          color="primary.main"
          sx={{ transition: 'color 0.3s ease-in-out' }}
        >
          {jobCategories[currentSlide].type}
        </Typography>
      </Typography>
      <Typography variant="subtitle1" fontSize='clamp(0.9rem, 3vw, 1rem)' gutterBottom>
        Select a {jobCategories[currentSlide].type.slice(0, -1)} and we'll show you relevant jobs for it!
      </Typography>
      <Box sx={{ position: 'relative', overflow: 'visible', marginY: 4 }}>
        <ArrowButton
          onClick={handlePrevSlide}
          sx={{ left: theme.spacing(-2) }}
        >
          <ChevronLeftIcon />
        </ArrowButton>
        <ArrowButton
          onClick={handleNextSlide}
          sx={{ right: theme.spacing(-2) }}
        >
          <ChevronRightIcon />
        </ArrowButton>
        <Slide direction="right" in={true} mountOnEnter unmountOnExit>
          <Grid container spacing={2}>
            {jobCategories[currentSlide].items.map((item, index) => (
              <Grid item xs={6} md={4} key={index}>
                <StyledCard onClick={handleCardClick}>
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {item.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.jobs} Jobs
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        </Slide>
      </Box>
    </Box>
  );
}