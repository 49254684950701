import React from 'react';
import { Box, Typography, Button, Paper, Container } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const CTASection = () => {
  return (
    <Container maxWidth={"lg"}
      elevation={3}
      sx={{
        background: 'url(https://joblixx-user-docs.blr1.cdn.digitaloceanspaces.com/web/mesh-bg-cta.png)',
        backgroundSize: 'cover',
        color: 'secondary.contrastText',
        p: 6,
        my: 2,
        borderRadius: 4,
        textAlign: 'center'
      }}
    >
      <Typography variant="h4" gutterBottom sx={{fontSize: 'clamp(2rem, 5vw, 2.5rem)'}}>
        Ready to save hours in searching jobs?
      </Typography>
      <Typography variant="subtitle1" paragraph sx={{fontSize: 'clamp(0.8rem, 5vw, 1rem)'}}>
        Discover how Joblixx saves you hours, streamlining your job hunt so you can focus on landing the perfect role faster.
      </Typography>
      <Button
              variant="contained"
              color="primary"
              href="https://app.joblixx.com/initial-info"
              endIcon={<ArrowForwardIcon />}
              sx={{ minWidth: { xs: '200px' }, minHeight: '50px', borderRadius: 15, fontSize: '1.2rem' }}
            >
              Explore jobs
            </Button>
    </Container>
  );
};

export default CTASection;